.details {
  @apply p-4 sm:p-8 flex w-full;

  & :global(.inner-wrapper) {
    @apply flex flex-col pb-4 w-full;
  }

  & :global(.figure-wrapper) {
    @apply mb-4;
  }

  & figure {
    @apply w-full relative pt-0 mb-0 h-auto overflow-hidden bg-black-100/10;
  }

  & :global(.position) {
    @apply mb-2.5 block font-sans break-words;
  }

  & :global(.full-name) {
    @apply font-sans text-2xl font-medium mb-2;
  }

  & :global(.description) {
    @apply text-base flex-grow overflow-y-auto mb-4;
  }

  & :global(.link) {
    @apply block pb-2 text-left;
  }

  & a {
    @apply underline;
  }
}
