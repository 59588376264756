/* Video */
.videoBlock {
  @apply text-white bg-black-100 py-13;

  & .videoContent {
    @apply flex flex-col items-start justify-center xl:ml-3;
  }

  &.layout-left {
    & .videoContent {
      @apply xl:mr-3;
    }
  }
}

.videoContainer {
  @apply mb-5 lg:mb-0 lg:col-span-2;
}

.videoGrid {
  @apply grid grid-cols-1 lg:grid-cols-3 gap-x-10 xl:gap-x-10;
}

.layout-left {
  & .videoContainer {
    @apply lg:col-start-2 lg:col-end-4 lg:row-start-1;
  }
}

.headline {
  @apply pb-10;
}
