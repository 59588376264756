.wrapper {
  & header {
    @apply flex flex-col justify-center items-center text-center bg-summer-green-50; /* @apply min-h-[200px]; */
    @apply mb-12;

    & h1 {
      @apply pt-8 pb-6 text-3xl lg:text-5xl md:text-4xl;
    }

    & :global(.description) {
      @apply pb-16 px-8 mx-auto text-xl max-w-1xl;
    }
  }

  & :global(.search-wrapper) {
    @apply w-full md:px-8;
    background: linear-gradient(to bottom, transparent 50%, #f8faea 50%);
  }

  & :global(.search-panel) {
    @apply bg-white p-6 md:p-8 shadow-sm mx-4 md:mx-0;
    @apply md:w-full md:max-w-8xl md:mx-auto;

    & form {
      @apply flex flex-col md:flex-row space-y-5 md:space-y-0 md:space-x-5;
    }

    & button {
      @apply hidden md:block min-w-[160px];
    }
  }

  & :global(.in-progress) {
    @apply my-20;
  }

  & :global(.no-result) {
    @apply my-20 text-center;
  }

  & :global(.result-wrapper) {
    @apply box-content px-4 mx-auto md:px-8;
    @apply mx-auto max-w-[850px];

    & :global(h2.result-title) {
      @apply text-xl pb-8;
    }
  }

  & :global(.paging) {
    @apply text-center;
  }
}
