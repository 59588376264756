.breadcrumbsLarge {
    width: calc(100% - 2rem);
    @apply pt-[70px] sm:pt-[100px] lg:pt-[140px] text-left;
    
    @screen md {
        width: calc(100% - 4rem);
    }
}

.list {
   @apply font-sans text-sm leading-snug;
}

.crumb {
  @apply mr-1;
  @apply inline;

  & :after {
    content: "/";
    @apply inline-block;
    @apply ml-1;
    @apply font-normal;
  }
}
