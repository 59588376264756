/* Includes all page specific styling */

.main {
  @apply text-lg;

  &:global(.is-white-page) {
    @apply bg-white;
  }

  & :global(> div[data-block="core/paragraph"]:last-of-type) {
    @apply pb-10 md:pb-15 lg:pb-20;
  }

  &:global(.has-search) {
    @apply mt-[171px] sm:mt-[197px] md:mt-[197px] lg:mt-[237px];

    & :global(div[data-block="breadcrumbs"]) {
      @apply pt-[14px] sm:pt-[14px] lg:pt-[14px] pb-[70px] sm:pb-[100px] lg:pb-[140px];
    }

    & :global(div[data-block="theme/hero"]) {
      @apply pb-12 lg:pb-20;
    }

    &:global(.has-admin-bar) {
      @apply pb-0 mt-[168px] sm:mt-[197px] lg:mt-[236px];
    }
  }

  &:global(.no-hero) {
    @apply pt-14 sm:pt-20 lg:pt-30;

    &:global(.has-admin-bar) {
      @apply pt-26 sm:pt-32 lg:pt-40;
    }
  }

  &:global(.has-admin-bar) {
    @apply pt-12 sm:pt-12 md:pt-9;
  }
}
