.card {
  @apply font-sans;

  & img {
    @apply image-has-hover-effect;
  }

  & :global(.headline) {
    @apply text-has-hover-effect;
  }

  &:hover {
    & img {
      @apply image-hover-effect;
    }

    & :global(.headline) {
      @apply text-hover-effect;
    }
  }
}

.headline {
  @apply inline font-sans text-xl font-medium text-has-hover-effect;
}

.imageWrapper {
  @apply block relative w-full h-0 mb-3.6 overflow-hidden pt-full;
}

.image {
  @apply absolute top-0 flex items-center justify-center w-full h-full bg-black-5 cursor-pointer;
}

.byLine {
  @apply mt-2.5 lg:mt-3 text-sm;
}

.lead {
  @apply line-clamp-3 text-base my-1;
}

.image {
  @apply object-cover object-center w-full h-full;
}
