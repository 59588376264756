/* theme/hero */
.hero {
  @apply relative flex flex-col;
  &:global(.has-articles) {
    @apply pb-36 md:pb-24;

    & :global(.credit) {
      @apply bottom-[80px] md:bottom-[100px] lg:bottom-auto lg:mb-0;
    }
  }

  &:global(.small) {
    min-height: 460px;
  }

  &:global(.large) {
    min-height: 700px;
  }

  & :global(.credit) {
    @apply absolute bottom-[20px] right-[20px] text-xs md:text-sm;
    @apply text-right max-w-8xl w-full mx-auto z-10;
    @apply md:right-[40px];
    @apply lg:relative lg:bottom-auto lg:right-auto lg:mb-4;

    width: calc(100% - 2rem);

    @media screen and (min-width: 768px) {
      width: calc(100% - 4rem);
    }
  }
}

.breadcrumbs {
  @apply pt-[70px] sm:pt-[100px] md:pt-[100px] lg:pt-[140px] mb-0 z-[2] h-[20px];
  width: calc(100% - 2rem);
}

.wrapper {
  @apply pt-10 mb-0 pb-12 md:pb-18 z-[2] box-border px-0 flex flex-1 items-center;
}

.innerWrapper {
  @apply w-full;
}

.breadcrumbs,
.wrapper {
  width: calc(100% - 2rem);

  @media screen and (min-width: 768px) {
    width: calc(100% - 4rem);
  }
}

.justify-center {
  @apply flex flex-col items-center mb-0 text-center;

  & .content {
    @apply mx-auto;
  }
}

.content {
  @apply max-w-1xl overflow-hidden;

  & > div {
    @apply p-0;
  }

  & h1 {
    @apply mb-5;
  }

  & p {
    @apply mb-7;

    &:global(.is-style-object-tag) {
      @apply mb-0;
    }
  }
}

.background {
  @apply absolute top-0 left-0 w-full h-full overflow-hidden bg-green-50;

  & img {
    @apply h-full w-full object-cover;
  }
}

.overlay {
  @apply top-0 absolute w-full h-full z-[1] bg-black-100;

  &:global(.opacity0) {
    @apply opacity-0;
  }

  &:global(.opacity10) {
    @apply opacity-10;
  }

  &:global(.opacity15) {
    @apply opacity-15;
  }

  &:global(.opacity20) {
    @apply opacity-20;
  }

  &:global(.opacity25) {
    @apply opacity-25;
  }

  &:global(.opacity30) {
    @apply opacity-30;
  }

  &:global(.opacity40) {
    @apply opacity-40;
  }

  &:global(.opacity50) {
    @apply opacity-50;
  }

  &:global(.opacity60) {
    @apply opacity-60;
  }

  &:global(.opacity70) {
    @apply opacity-70;
  }
}

.gradient {
  @apply top-0 absolute w-full h-full z-[2];
}

.videoOverlay {
  @apply object-cover w-full h-full xl:h-auto;
}

.mediaAuthor {
  @apply mt-4 font-sans text-sm leading-normal;
}

.articlesWrapper {
  @apply mt-[-100px] md:mt-[-140px] lg:mt-[-160px] relative z-[5];
}

.articles {
  @apply w-full bg-white shadow p-4 lg:px-0 lg:py-8;
  @apply lg:flex;

  & :global(.button-wrapper) {
    @apply pt-4 lg:pt-0 lg:px-8;
    @apply lg:flex lg:justify-center lg:items-center;

    & a {
      @apply inline-block;
    }
  }
}

.article {
  @apply block overflow-hidden py-4 border-b border-black-100/10;
  @apply lg:border-r lg:border-b-0 w-full lg:py-0 lg:px-8;

  &:first-of-type {
    @apply pt-0;
  }

  & h2 {
    @apply text-xl font-sans font-medium;
    @apply text-has-hover-effect;
  }

  & span {
    @apply block text-sm mt-2;
  }
}
