.modal {
  @apply bg-white relative flex rounded max-w-3xl;
  width: calc(100% - 2rem);
  max-height: calc(100% - 2rem);

  & :global(.close-wrapper) {
    @apply absolute outline-none;
    @apply flex justify-center items-center z-100;
    @apply right-2 top-2 sm:right-3 sm:top-3 md:-right-5 md:-top-5 w-10 h-10;

    & :global(.close) {
      @apply bg-black-100 rounded-full text-white cursor-pointer;
      @apply flex justify-center items-center;
      @apply w-10 h-10 md:w-8 md:h-8;

      & :global(.icon) {
        @apply fill-white w-[12px];
      }
    }
  }
}
